import { createTheme, rem } from '@mantine/core';

export const linearGray: [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
] = [
    '#FAFAFA',
    '#F5F5F5',
    '#E5E5E5',
    '#D4D4D4',
    '#A3A3A3',
    '#737373',
    '#525252',
    '#404040',
    '#262626',
    '#171717',
  ];

export const linearBlue: [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
] = [
    '#EFF6FF',
    '#DBEAFE',
    '#BFDBFE',
    '#93C5FD',
    '#60A5FA',
    '#3B82F6',
    '#2563EB',
    '#1D4ED8',
    '#1E40AF',
    '#1E3A8A',
  ];

export const MantineTheme = createTheme({
  fontFamily: 'Inter, sans-serif',
  headings: {
    sizes: {
      h1: {
        fontSize: '3rem', // Equivalent to 48px
        fontWeight: '700',
        lineHeight: '1.2',
      },
      h2: {
        fontSize: '2.5rem', // Equivalent to 40px
        fontWeight: '600',
        lineHeight: '1.25',
      },
      h3: {
        fontSize: '2rem', // Equivalent to 32px
        fontWeight: '600',
        lineHeight: '1.3',
      },
      h4: {
        fontSize: '1.5rem', // Equivalent to 24px
        fontWeight: '500',
        lineHeight: '1.35',
      },
      h5: {
        fontSize: '1.25rem', // Equivalent to 20px
        fontWeight: '500',
        lineHeight: '1.4',
      },
      h6: {
        fontSize: '1rem', // Equivalent to 16px
        fontWeight: '400',
        lineHeight: '1.5',
      },
    },
  },
  colors: {
    linearGray,
    linearBlue,
  },

  spacing: {
    xs: rem(4),
    sm: rem(8),
    md: rem(16),
    lg: rem(24),
    xl: rem(32),
    '2xl': rem(40),
    '3xl': rem(48),
  },

  // Responsive breakpoints
  breakpoints: {
    xs: '36em', // 576px
    sm: '48em', // 768px
    md: '62em', // 992px
    lg: '75em', // 1200px
    xl: '88em', // 1400px
  },

  defaultRadius: 'sm',

  other: {
    light: {
      background: '#FFFFFF',
      surface: '#FAFAFA',
      borderColor: '#E5E5E5',
      textPrimary: '#171717',
      textSecondary: '#737373',
    },
    dark: {
      background: '#171717',
      surface: '#262626',
      borderColor: '#404040',
      textPrimary: '#FFFFFF',
      textSecondary: '#A3A3A3',
    },
  },

  components: {
    Button: {
      styles: (theme) => ({
        root: {
          borderRadius: '6px',
          fontWeight: 500,
        },
      }),
    },
    Card: {
      styles: (theme) => ({
        root: {
          borderRadius: '8px',
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.linearGray[8] : theme.white,
        },
      }),
    },

    Title: {
      styles: (theme) => ({
        root: {
          fontWeight: 600,
          color: theme.colorScheme === 'dark' ? theme.white : theme.colors.linearGray[9],
          '&[dataOrder="1"]': {
            fontSize: theme.fontSizes['4xl'],
            lineHeight: 1.1, // instead of theme.lineHeight.xs
            letterSpacing: '-0.02em',
            marginBottom: theme.spacing.lg,
          },
          '&[dataOrder="2"]': {
            fontSize: theme.fontSizes['3xl'],
            lineHeight: 1.2, // instead of theme.lineHeight.sm
            letterSpacing: '-0.01em',
            marginBottom: theme.spacing.md,
          },
          '&[dataOrder="3"]': {
            fontSize: theme.fontSizes['2xl'],
            lineHeight: 1.4, // instead of theme.lineHeight.md
            marginBottom: theme.spacing.sm,
          },
          '&[dataOrder="4"]': {
            fontSize: theme.fontSizes.xl,
            lineHeight: 1.6, // instead of theme.lineHeight.lg
            marginBottom: theme.spacing.sm,
          },
        },
      }),
    },

    Mark: {
      styles: (theme) => ({
        root: {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.linearBlue[9] : theme.colors.linearBlue[1],
          color:
            theme.colorScheme === 'dark' ? theme.colors.linearBlue[2] : theme.colors.linearBlue[8],
          padding: '0 4px',
          borderRadius: '4px',
        },
      }),
    },

    Anchor: {
      styles: (theme) => ({
        root: {
          color: theme.colors.linearBlue[theme.colorScheme === 'dark' ? 4 : 6],
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      }),
    },

    Code: {
      styles: (theme) => ({
        root: {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[8] : theme.colors.linearGray[1],
          color:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[3] : theme.colors.linearGray[7],
          padding: '2px 6px',
          borderRadius: '4px',
          fontSize: '0.9em',
        },
      }),
    },

    Text: {
      styles: (theme) => ({
        root: {
          color:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[4] : theme.colors.linearGray[7],
          '&[dataSize="xs"]': {
            fontSize: theme.fontSizes.xs,
            lineHeight: 1.4,
          },
          '&[dataSize="sm"]': {
            fontSize: theme.fontSizes.sm,
            lineHeight: 1.4,
          },
          '&[dataSize="md"]': {
            fontSize: theme.fontSizes.md,
            lineHeight: 1.4,
          },
          '&[dataSize="lg"]': {
            fontSize: theme.fontSizes.lg,
            lineHeight: 1.4,
          },
          '&[dataSize="xl"]': {
            fontSize: theme.fontSizes.xl,
            lineHeight: 1.4,
          },
        },
      }),
    },

    List: {
      styles: (theme) => ({
        root: {
          marginBottom: theme.spacing.lg,
          paddingLeft: theme.spacing.xl,
        },
        item: {
          color:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[4] : theme.colors.linearGray[7],
          marginBottom: theme.spacing.xs,
          lineHeight: 1.4,
        },
        ordered: {
          counterReset: 'item',
          '& [data-list-item]::before': {
            content: 'counter(item)',
            counterIncrement: 'item',
            color:
              theme.colorScheme === 'dark'
                ? theme.colors.linearGray[5]
                : theme.colors.linearGray[6],
            fontWeight: 500,
            marginRight: theme.spacing.sm,
          },
        },
        unordered: {
          '& [data-list-item]::before': {
            content: '"•"',
            color:
              theme.colorScheme === 'dark'
                ? theme.colors.linearGray[5]
                : theme.colors.linearGray[6],
            fontWeight: 'bold',
            marginRight: theme.spacing.sm,
          },
        },
      }),
    },

    // Blockquote styling
    Blockquote: {
      styles: (theme) => ({
        root: {
          borderLeft: `4px solid ${theme.colorScheme === 'dark' ? theme.colors.linearBlue[6] : theme.colors.linearBlue[5]
            }`,
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[8] : theme.colors.linearGray[0],
          padding: theme.spacing.lg,
          marginBottom: theme.spacing.lg,
          borderRadius: theme.radius.sm,
        },
        cite: {
          color:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[4] : theme.colors.linearGray[6],
          fontSize: theme.fontSizes.sm,
          marginTop: theme.spacing.xs,
        },
      }),
    },

    // Table styling
    Table: {
      styles: (theme) => ({
        root: {
          width: '100%',
          marginBottom: theme.spacing.xl,
          borderCollapse: 'separate',
          borderSpacing: 0,
          '& th': {
            textAlign: 'left',
            padding: theme.spacing.sm,
            borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.linearGray[7] : theme.colors.linearGray[3]
              }`,
            color:
              theme.colorScheme === 'dark'
                ? theme.colors.linearGray[3]
                : theme.colors.linearGray[7],
            fontSize: theme.fontSizes.sm,
            fontWeight: 600,
          },
          '& td': {
            padding: theme.spacing.sm,
            borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.linearGray[8] : theme.colors.linearGray[2]
              }`,
            color:
              theme.colorScheme === 'dark'
                ? theme.colors.linearGray[4]
                : theme.colors.linearGray[7],
          },
          '& tr:hover td': {
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.linearGray[8]
                : theme.colors.linearGray[0],
          },
        },
      }),
    },

    // Form elements styling
    TextInput: {
      styles: (theme) => ({
        root: {
          marginBottom: theme.spacing.md,
        },
        input: {
          border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.linearGray[7] : theme.colors.linearGray[3]
            }`,
          borderRadius: theme.radius.sm,
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.linearGray[9] : theme.white,
          color: theme.colorScheme === 'dark' ? theme.white : theme.black,
          padding: `${theme.spacing.sm} ${theme.spacing.md}`,
          '&:focus': {
            borderColor: theme.colors.linearBlue[5],
            boxShadow: `0 0 0 1px ${theme.colors.linearBlue[5]}`,
          },
          '&::placeholder': {
            color:
              theme.colorScheme === 'dark'
                ? theme.colors.linearGray[5]
                : theme.colors.linearGray[5],
          },
        },
        label: {
          marginBottom: theme.spacing.xs,
          fontSize: theme.fontSizes.sm,
          fontWeight: 500,
          color:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[3] : theme.colors.linearGray[7],
        },
      }),
    },

    Select: {
      styles: (theme) => ({
        root: {
          marginBottom: theme.spacing.md,
        },
        input: {
          border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.linearGray[7] : theme.colors.linearGray[3]
            }`,
          borderRadius: theme.radius.sm,
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.linearGray[9] : theme.white,
          color: theme.colorScheme === 'dark' ? theme.white : theme.black,
          padding: `${theme.spacing.sm} ${theme.spacing.md}`,
          '&:focus': {
            borderColor: theme.colors.linearBlue[5],
            boxShadow: `0 0 0 1px ${theme.colors.linearBlue[5]}`,
          },
        },
        label: {
          marginBottom: theme.spacing.xs,
          fontSize: theme.fontSizes.sm,
          fontWeight: 500,
          color:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[3] : theme.colors.linearGray[7],
        },
        item: {
          padding: `${theme.spacing.xs} ${theme.spacing.md}`,
          '&[data-selected]': {
            backgroundColor: theme.colors.linearBlue[theme.colorScheme === 'dark' ? 8 : 1],
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,
          },
          '&[data-hovered]': {
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.linearGray[8]
                : theme.colors.linearGray[0],
          },
        },
      }),
    },

    Checkbox: {
      styles: (theme) => ({
        root: {
          marginBottom: theme.spacing.sm,
        },
        input: {
          borderColor:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[6] : theme.colors.linearGray[4],
          '&:checked': {
            backgroundColor: theme.colors.linearBlue[6],
            borderColor: theme.colors.linearBlue[6],
          },
        },
        label: {
          fontSize: theme.fontSizes.sm,
          color:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[3] : theme.colors.linearGray[7],
        },
      }),
    },

    Radio: {
      styles: (theme) => ({
        root: {
          marginBottom: theme.spacing.sm,
        },
        radio: {
          borderColor:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[6] : theme.colors.linearGray[4],
          '&:checked': {
            backgroundColor: theme.colors.linearBlue[6],
            borderColor: theme.colors.linearBlue[6],
          },
        },
        label: {
          fontSize: theme.fontSizes.sm,
          color:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[3] : theme.colors.linearGray[7],
        },
      }),
    },

    // Textarea styling
    Textarea: {
      styles: (theme) => ({
        root: {
          marginBottom: theme.spacing.md,
        },
        input: {
          border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.linearGray[7] : theme.colors.linearGray[3]
            }`,
          borderRadius: theme.radius.sm,
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.linearGray[9] : theme.white,
          color: theme.colorScheme === 'dark' ? theme.white : theme.black,
          padding: theme.spacing.sm,
          minHeight: rem(100),
          '&:focus': {
            borderColor: theme.colors.linearBlue[5],
            boxShadow: `0 0 0 1px ${theme.colors.linearBlue[5]}`,
          },
          '&::placeholder': {
            color:
              theme.colorScheme === 'dark'
                ? theme.colors.linearGray[5]
                : theme.colors.linearGray[5],
          },
        },
      }),
    },

    // Switch styling
    Switch: {
      styles: (theme) => ({
        root: {
          marginBottom: theme.spacing.sm,
        },
        track: {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[7] : theme.colors.linearGray[3],
          borderRadius: rem(10),
          width: rem(36),
          height: rem(20),
          '&[data-checked]': {
            backgroundColor: theme.colors.linearBlue[6],
          },
        },
        thumb: {
          backgroundColor: theme.white,
          borderWidth: 0,
          width: rem(16),
          height: rem(16),
        },
        label: {
          fontSize: theme.fontSizes.sm,
          color:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[3] : theme.colors.linearGray[7],
        },
      }),
    },

    // Modal styling
    Modal: {
      styles: (theme) => ({
        root: {
          backdropFilter: 'blur(4px)',
        },
        modal: {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.linearGray[9] : theme.white,
          borderRadius: theme.radius.md,
          boxShadow:
            theme.colorScheme === 'dark'
              ? '0 0 50px rgba(0, 0, 0, 0.5)'
              : '0 0 50px rgba(0, 0, 0, 0.1)',
          border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.linearGray[7] : theme.colors.linearGray[2]
            }`,
        },
        header: {
          borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.linearGray[7] : theme.colors.linearGray[2]
            }`,
          padding: theme.spacing.md,
        },
        title: {
          fontWeight: 600,
          fontSize: theme.fontSizes.lg,
        },
        body: {
          padding: theme.spacing.lg,
        },
      }),
    },

    // Navigation components
    Navbar: {
      styles: (theme) => ({
        root: {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.linearGray[9] : theme.white,
          borderRight: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.linearGray[7] : theme.colors.linearGray[2]
            }`,
        },
      }),
    },

    NavLink: {
      styles: (theme) => ({
        root: {
          padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
          borderRadius: theme.radius.sm,
          color:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[3] : theme.colors.linearGray[7],
          fontSize: theme.fontSizes.sm,
          '&[data-active]': {
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.linearBlue[9]
                : theme.colors.linearBlue[0],
            color:
              theme.colorScheme === 'dark'
                ? theme.colors.linearBlue[2]
                : theme.colors.linearBlue[7],
            fontWeight: 500,
          },
          '&:hover': {
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.linearGray[8]
                : theme.colors.linearGray[0],
          },
        },
        icon: {
          color: 'inherit',
        },
      }),
    },

    // Toast notifications
    Notification: {
      styles: (theme) => ({
        root: {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.linearGray[8] : theme.white,
          borderRadius: theme.radius.md,
          border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.linearGray[7] : theme.colors.linearGray[2]
            }`,
          boxShadow:
            theme.colorScheme === 'dark'
              ? '0 4px 6px rgba(0, 0, 0, 0.3)'
              : '0 4px 6px rgba(0, 0, 0, 0.05)',
          '&[data-type="success"]': {
            borderLeft: `4px solid ${theme.colors.green[6]}`,
          },
          '&[data-type="error"]': {
            borderLeft: `4px solid ${theme.colors.red[6]}`,
          },
          '&[data-type="info"]': {
            borderLeft: `4px solid ${theme.colors.linearBlue[6]}`,
          },
        },
        title: {
          fontWeight: 600,
          fontSize: theme.fontSizes.sm,
        },
        description: {
          fontSize: theme.fontSizes.sm,
          color:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[4] : theme.colors.linearGray[6],
        },
        closeButton: {
          color:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[4] : theme.colors.linearGray[6],
          '&:hover': {
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.linearGray[7]
                : theme.colors.linearGray[1],
          },
        },
      }),
    },

    // Progress indicators
    Progress: {
      styles: (theme) => ({
        root: {
          marginBottom: theme.spacing.md,
        },
        track: {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[7] : theme.colors.linearGray[2],
          height: rem(6),
          borderRadius: theme.radius.xl,
        },
        bar: {
          backgroundColor: theme.colors.linearBlue[6],
          borderRadius: theme.radius.xl,
          transition: 'width 300ms ease',
        },
      }),
    },

    Loader: {
      styles: (theme) => ({
        root: {
          borderColor:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[4] : theme.colors.linearGray[3],
          borderTopColor: theme.colors.linearBlue[6],
        },
      }),
    },

    // Tooltip styling
    Tooltip: {
      styles: (theme) => ({
        root: {
          position: 'relative',
        },
        tooltip: {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[8] : theme.colors.linearGray[9],
          color: theme.colorScheme === 'dark' ? theme.white : theme.white,
          fontSize: theme.fontSizes.xs,
          padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
          borderRadius: theme.radius.sm,
          boxShadow:
            theme.colorScheme === 'dark'
              ? '0 4px 6px rgba(0, 0, 0, 0.3)'
              : '0 4px 6px rgba(0, 0, 0, 0.1)',
          maxWidth: '200px',
          lineHeight: 1.4,
        },
        arrow: {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[8] : theme.colors.linearGray[9],
        },
      }),
    },

    // Popover styling
    Popover: {
      styles: (theme) => ({
        dropdown: {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.linearGray[9] : theme.white,
          border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.linearGray[7] : theme.colors.linearGray[2]
            }`,
          boxShadow:
            theme.colorScheme === 'dark'
              ? '0 4px 6px rgba(0, 0, 0, 0.3)'
              : '0 4px 6px rgba(0, 0, 0, 0.1)',
          borderRadius: theme.radius.md,
          padding: theme.spacing.md,
        },
        arrow: {
          border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.linearGray[7] : theme.colors.linearGray[2]
            }`,
        },
      }),
    },

    // Accordion styling
    Accordion: {
      styles: (theme) => ({
        item: {
          borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.linearGray[7] : theme.colors.linearGray[2]
            }`,
          '&:last-of-type': {
            borderBottom: 'none',
          },
        },
        control: {
          padding: theme.spacing.md,
          '&:hover': {
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.linearGray[8]
                : theme.colors.linearGray[0],
          },
        },
        label: {
          color: theme.colorScheme === 'dark' ? theme.white : theme.black,
          fontSize: theme.fontSizes.sm,
          fontWeight: 500,
        },
        content: {
          padding: theme.spacing.md,
          paddingTop: 0,
          fontSize: theme.fontSizes.sm,
          color:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[3] : theme.colors.linearGray[7],
        },
        chevron: {
          color:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[4] : theme.colors.linearGray[6],
        },
      }),
    },

    // Tabs styling
    Tabs: {
      styles: (theme) => ({
        root: {
          marginBottom: theme.spacing.xl,
        },
        list: {
          borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.linearGray[7] : theme.colors.linearGray[2]
            }`,
          gap: theme.spacing.md,
        },
        tab: {
          padding: `${theme.spacing.xs} ${theme.spacing.md}`,
          color:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[4] : theme.colors.linearGray[6],
          fontSize: theme.fontSizes.sm,
          fontWeight: 500,
          borderBottom: '2px solid transparent',
          marginBottom: '-1px',
          '&[data-active]': {
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,
            borderBottomColor: theme.colors.linearBlue[6],
          },
          '&:hover': {
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,
          },
        },
        panel: {
          padding: theme.spacing.md,
          paddingLeft: 0,
          paddingRight: 0,
        },
      }),
    },

    // Alert/Banner styling
    Alert: {
      styles: (theme) => ({
        root: {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[8] : theme.colors.linearGray[0],
          border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.linearGray[7] : theme.colors.linearGray[2]
            }`,
          borderRadius: theme.radius.md,
          padding: theme.spacing.md,
          marginBottom: theme.spacing.md,
          '&[data-type="info"]': {
            borderLeft: `4px solid ${theme.colors.linearBlue[6]}`,
          },
          '&[data-type="success"]': {
            borderLeft: `4px solid ${theme.colors.green[6]}`,
          },
          '&[data-type="warning"]': {
            borderLeft: `4px solid ${theme.colors.yellow[6]}`,
          },
          '&[data-type="error"]': {
            borderLeft: `4px solid ${theme.colors.red[6]}`,
          },
        },
        title: {
          fontWeight: 600,
          fontSize: theme.fontSizes.sm,
          marginBottom: theme.spacing.xs,
        },
        message: {
          color:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[3] : theme.colors.linearGray[7],
          fontSize: theme.fontSizes.sm,
          lineHeight: 1.5,
        },
        closeButton: {
          color:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[4] : theme.colors.linearGray[6],
        },
      }),
    },

    Badge: {
      styles: (theme) => ({
        root: {
          textTransform: 'none',
          fontSize: theme.fontSizes.xs,
          height: rem(22),
          paddingLeft: theme.spacing.sm,
          paddingRight: theme.spacing.sm,
          '&[data-variant="filled"]': {
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.linearBlue[9]
                : theme.colors.linearBlue[1],
            color:
              theme.colorScheme === 'dark'
                ? theme.colors.linearBlue[2]
                : theme.colors.linearBlue[8],
          },
          '&[data-variant="outline"]': {
            borderColor:
              theme.colorScheme === 'dark'
                ? theme.colors.linearGray[6]
                : theme.colors.linearGray[4],
            color:
              theme.colorScheme === 'dark'
                ? theme.colors.linearGray[4]
                : theme.colors.linearGray[7],
          },
        },
      }),
    },

    DragDropContext: {
      styles: (theme) => ({
        draggable: {
          cursor: 'grab',
          '&:active': { cursor: 'grabbing' },
          transition: 'background-color 150ms ease',
          '&[data-dragging]': {
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.linearGray[8]
                : theme.colors.linearGray[1],
            boxShadow:
              theme.colorScheme === 'dark'
                ? '0 8px 12px rgba(0, 0, 0, 0.3)'
                : '0 8px 12px rgba(0, 0, 0, 0.1)',
          },
        },
        dropzone: {
          padding: theme.spacing.xs,
          border: `2px dashed ${theme.colorScheme === 'dark' ? theme.colors.linearGray[6] : theme.colors.linearGray[3]
            }`,
          borderRadius: theme.radius.md,
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[9] : theme.colors.linearGray[0],
          transition: 'all 150ms ease',
          '&[data-active]': {
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.linearBlue[9]
                : theme.colors.linearBlue[0],
            borderColor: theme.colors.linearBlue[5],
          },
        },
      }),
    },

    // Command Palette
    CommandPalette: {
      styles: (theme) => ({
        root: {
          position: 'fixed',
          top: '20%',
          left: '50%',
          transform: 'translateX(-50%)',
          width: '100%',
          maxWidth: rem(640),
          zIndex: 1000,
          animation: `fadeIn 200ms ease`,
        },
        input: {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.linearGray[9] : theme.white,
          border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.linearGray[7] : theme.colors.linearGray[3]
            }`,
          borderRadius: theme.radius.md,
          padding: theme.spacing.md,
          fontSize: theme.fontSizes.lg,
          '&:focus': {
            borderColor: theme.colors.linearBlue[5],
          },
        },
        results: {
          maxHeight: rem(400),
          overflowY: 'auto',
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.linearGray[9] : theme.white,
          border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.linearGray[7] : theme.colors.linearGray[3]
            }`,
          borderRadius: theme.radius.md,
          marginTop: theme.spacing.xs,
        },
        item: {
          padding: theme.spacing.sm,
          cursor: 'pointer',
          '&[data-selected]': {
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.linearGray[8]
                : theme.colors.linearGray[1],
          },
        },
      }),
    },

    // Split Pane
    SplitPane: {
      styles: (theme) => ({
        root: {
          display: 'flex',
          height: '100%',
        },
        divider: {
          width: rem(4),
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[7] : theme.colors.linearGray[2],
          cursor: 'col-resize',
          transition: 'background-color 150ms ease',
          '&:hover': {
            backgroundColor: theme.colors.linearBlue[5],
          },
        },
        panel: {
          minWidth: 0,
          transition: 'width 150ms ease',
        },
      }),
    },

    // Virtual List
    VirtualList: {
      styles: (theme) => ({
        root: {
          height: '100%',
          overflowY: 'auto',
        },
        item: {
          padding: theme.spacing.sm,
          borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.linearGray[7] : theme.colors.linearGray[2]
            }`,
          animation: `slideIn 200ms ease`,
        },
      }),
    },

    // Skeleton Loading
    Skeleton: {
      styles: (theme) => ({
        root: {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[8] : theme.colors.linearGray[1],
          backgroundImage: `linear-gradient(90deg, 
            ${theme.colorScheme === 'dark' ? theme.colors.linearGray[8] : theme.colors.linearGray[1]} 0%,
            ${theme.colorScheme === 'dark' ? theme.colors.linearGray[7] : theme.colors.linearGray[2]} 50%,
            ${theme.colorScheme === 'dark' ? theme.colors.linearGray[8] : theme.colors.linearGray[1]} 100%
          )`,
          backgroundSize: '1000px 100%',
          animation: `shimmer 2s infinite linear`,
        },
      }),
    },

    // Rich Text Editor
    RichTextEditor: {
      styles: (theme) => ({
        root: {
          border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.linearGray[7] : theme.colors.linearGray[3]
            }`,
          borderRadius: theme.radius.md,
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.linearGray[9] : theme.white,
        },
        toolbar: {
          borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.linearGray[7] : theme.colors.linearGray[3]
            }`,
          padding: theme.spacing.xs,
        },
        content: {
          padding: theme.spacing.md,
          minHeight: rem(200),
          '& p': {
            marginBottom: theme.spacing.sm,
          },
        },
      }),
    },

    // Activity Feed
    ActivityFeed: {
      styles: (theme) => ({
        root: {
          padding: theme.spacing.md,
        },
        item: {
          display: 'flex',
          gap: theme.spacing.md,
          marginBottom: theme.spacing.lg,
          animation: `fadeIn 300ms ease`,
        },
        timeline: {
          width: rem(2),
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.linearGray[7] : theme.colors.linearGray[3],
          flexShrink: 0,
        },
        content: {
          flex: 1,
        },
      }),
    },
  },
});

// Responsive utils
export const responsiveUtils = {
  useBreakpoint: () => {
    // Implementation for breakpoint hook
  },

  createResponsiveStyle: (styles) => {
    // Implementation for responsive style generator
  },

  adaptiveLayout: (components) => {
    // Implementation for layout adaptation
  },
};
