import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Hidden, IconButton, Toolbar } from '@mui/material';
import { IconMenu } from '@tabler/icons-react';
import Logo from '../Logo';

interface DocsNavbarProps {
  onSidebarMobileOpen?: () => void;
}

const DocsNavbar: FC<DocsNavbarProps> = ({ onSidebarMobileOpen }: DocsNavbarProps) => (
  <AppBar
    sx={{
      backgroundColor: 'background.paper',
      color: 'text.primary',
      boxShadow: 'none',
      borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      zIndex: (theme) => theme.zIndex.drawer + 100,
    }}
  >
    <Toolbar sx={{ height: 64 }}>
      <Hidden lgUp>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
        >
          <IconMenu fontSize="small" />
        </IconButton>
      </Hidden>
      <Hidden lgDown>
        <RouterLink to="/">
          <Logo
            style={{
              height: 40,
              width: 40,
            }}
          />
        </RouterLink>
      </Hidden>
    </Toolbar>
  </AppBar>
);

export default DocsNavbar;
