import type { FC } from 'react';
import { Box } from '@mantine/core';
import { ReactComponent as LogoSvg } from '../graphics/logo.svg';
import { ReactComponent as SculptureSVG } from '../graphics/sculpture.svg';

interface LogoProps {
  height?: string | number;
  width?: string | number;
  style?: React.CSSProperties;
}

const Logo: FC<LogoProps> = ({ height = 52, width = 52, style }) => (
  <Box
    component={SculptureSVG}
    style={{
      display: 'flex',
      filter: 'invert(100%) sepia(0) saturate(25%) brightness(108%) contrast(108%)',
      ...style,
      height,
      width,
    }}
  />
);

export default Logo;
