import { useEffect, FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Group,
  ScrollArea,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import {
  IconPuzzle,
  IconAddressBook,
  IconSearch,
  IconSwords,
  IconNote,
  IconBell,
  IconCalendarEvent,
  IconLayoutKanban,
  IconUserCircle,
  IconX,
} from '@tabler/icons-react';

import { getCustomerDataForSelf } from 'src/slices/customerData';
import CustomerDataApi from 'src/apis/CustomerDataApi';
import Cookie from 'src/lib/cookies';
import { CustomerDataResponse } from 'src/apis/types/CustomerDataAPITypes';

import Logo from '../Logo';
import NavSection from '../NavSection';
import { RootState, useDispatch, useSelector } from '../../store';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const customerDataSelfSelector = (state: RootState): CustomerDataResponse => {
  const { customerData } = state.customerData;
  return customerData.self;
};

const sections = [
  {
    title: '',
    items: [
      { title: 'Overview', path: '/dashboard', icon: <IconSwords size={18} /> },
      { title: 'Account', path: '/dashboard/account', icon: <IconUserCircle size={18} /> },
    ],
  },
  {
    title: '',
    items: [
      { title: 'Apps', path: '/dashboard/apps', icon: <IconPuzzle size={18} /> },
      { title: 'Search', path: '/dashboard/search', icon: <IconSearch size={18} /> },
      { title: 'Kanban', path: '/dashboard/kanban', icon: <IconLayoutKanban size={18} /> },
      { title: 'Calendar', path: '/dashboard/calendar', icon: <IconCalendarEvent size={18} /> },
      { title: 'Contacts', path: '/dashboard/contacts', icon: <IconAddressBook size={18} /> },
      { title: 'Notepad', path: '/dashboard/notepad', icon: <IconNote size={18} /> },
      { title: 'Notifications', path: '/dashboard/notifications', icon: <IconBell size={18} /> },
    ],
  },
];

const DashboardSidebar: FC<DashboardSidebarProps> = ({ onMobileClose, openMobile: open }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useMantineTheme();
  const currentCustomerData = useSelector(customerDataSelfSelector);
  let customerName = currentCustomerData?.name || '';
  let customerAvatar = currentCustomerData?.avatarURI || '';

  const fetchCustomerData = async () => {
    if (!currentCustomerData) {
      dispatch(getCustomerDataForSelf());
      const uuid = Cookie.getCookie(Cookie.Keys.UUID);
      const response = await CustomerDataApi.getCustomerDataByUUID(uuid);
      customerName = response.name;
      customerAvatar = response.avatarURI;
    }
  };

  useEffect(() => {
    fetchCustomerData();
    if (open && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <ScrollArea
      style={{
        backgroundColor: theme.colors.dark[7],
        height: '100%',
        display: 'flex',
      }}
    >
      <Stack style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {sections.map((section) => (
          <>
            <NavSection
              key={section.title}
              pathname={location.pathname}
              style={{ marginTop: '0px' }}
              {...section}
            />
            <Divider style={{ margin: '0 0' }} />
          </>
        ))}
      </Stack>
    </ScrollArea>
  );

  return (
    <>
      {open && (
        <Drawer
          opened={open}
          onClose={onMobileClose}
          withCloseButton={true}
          closeButtonProps={{
            icon: <IconX size={20} />, // Replace with your preferred icon
          }}
          padding="0"
          size={200}
          style={{
            height: '100%',
          }}
          position="left"
          bg={theme.colors.dark[7]}
          overlayProps={{ opacity: 0.5, blur: 2 }}
          styles={{
            header: {
              backgroundColor: theme.colors.dark[7],
              borderBottom: '1px solid #e9ecef',
              height: '4rem',
            },
            content: {
              backgroundColor: theme.colors.dark[7],
            },
            close: {
              marginRight: '1rem',
            },
          }}
          title={
            <Group style={{ gap: '4px', width: '100%', paddingLeft: '1rem' }}>
              <Logo
                height={40}
                width={40}
              />
            </Group>
          }
          transitionProps={{
            transition: 'slide-right', // Use slide-left transition
            duration: 3000, // Duration of the transition
            timingFunction: 'ease',
          }}
        >
          {content}
        </Drawer>
      )}
    </>
  );
};

export default DashboardSidebar;
