import { FC, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Paper,
  Box,
  Burger,
  Container,
  Group,
  rem,
  useMantineTheme,
  TextInput,
  Kbd,
  Menu,
  ActionIcon,
  Input,
  Tooltip,
} from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import {
  IconSearch,
  IconBell,
  IconCalendar,
  IconPlus,
  IconNote,
  IconCalendarEvent,
  IconLayoutKanban,
  IconLayoutSidebar,
} from '@tabler/icons-react';
import Logo from '../Logo';
import AccountPopover from './AccountPopover';
import styles from './navbar.module.css';

interface DashboardNavbarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbar: FC<DashboardNavbarProps> = ({ onSidebarMobileOpen }) => {
  const theme = useMantineTheme();
  const searchInputRef = useRef<HTMLInputElement>(null);

  useHotkeys([
    [
      '/',
      () => {
        searchInputRef.current?.focus();
        setTimeout(() => {
          if (searchInputRef.current) searchInputRef.current.value = '';
        }, 0);
      },
    ],
  ]);

  return (
    <Paper
      h={rem(64)}
      radius={0}
      withBorder={false}
      style={{
        backgroundColor: theme.colors.dark[7],
        borderBottom: `1px solid ${theme.colors.linearGray[6]}`,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 200,
      }}
    >
      <Container
        fluid
        h="100%"
      >
        <Group
          justify="space-between"
          h="100%"
          wrap="nowrap"
        >
          <Group wrap="nowrap">
            <Tooltip
              label="Toggle sidebar"
              withArrow
            >
              <ActionIcon
                variant="subtle"
                color="gray"
                onClick={onSidebarMobileOpen}
              >
                <IconLayoutSidebar size={20} />
              </ActionIcon>
            </Tooltip>
            <RouterLink to="/dashboard">
              <Logo
                height={40}
                width={40}
              />
            </RouterLink>
          </Group>

          <Group
            style={{
              gap: '6px',
            }}
            align="center"
          >
            <TextInput
              className={styles['navbar-search']}
              ref={searchInputRef}
              placeholder="Type / to search..."
              rightSection={<Kbd style={{ border: 'none', background: 'transparent' }}>/</Kbd>}
              styles={() => ({
                root: { width: rem(300) },
                display: 'flex',
                marginBottom: 0,
                input: {
                  height: rem(36), // Consistent height for alignment
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  color: theme.white,
                  paddingLeft: rem(36), // Space for the left icon
                  '&::placeholder': {
                    color: 'rgba(255, 255, 255, 0.7)',
                  },
                },
              })}
              leftSection={<IconSearch size="1rem" />}
            />

            <Tooltip
              label="Kanban"
              withArrow
            >
              <ActionIcon
                variant="subtle"
                color="gray"
                size="lg"
              >
                <IconLayoutKanban size={20} />
              </ActionIcon>
            </Tooltip>

            <Tooltip
              label="Notifications"
              withArrow
            >
              <ActionIcon
                variant="subtle"
                color="gray"
                size="lg"
              >
                <IconBell size={20} />
              </ActionIcon>
            </Tooltip>

            <Tooltip
              label="Calendar"
              withArrow
            >
              <ActionIcon
                variant="subtle"
                color="gray"
                size="lg"
              >
                <IconCalendar size={20} />
              </ActionIcon>
            </Tooltip>

            <Menu
              position="bottom-end"
              withinPortal
            >
              <Menu.Target>
                <Tooltip
                  label="Create new..."
                  withArrow
                >
                  <Menu.Target>
                    <ActionIcon
                      variant="subtle"
                      color="gray"
                      size="lg"
                    >
                      <IconPlus size={20} />
                    </ActionIcon>
                  </Menu.Target>
                </Tooltip>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item leftSection={<IconNote size={14} />}>New Note</Menu.Item>
                <Menu.Item leftSection={<IconCalendarEvent size={14} />}>New Event</Menu.Item>
              </Menu.Dropdown>
            </Menu>

            <AccountPopover />
          </Group>
        </Group>
      </Container>
    </Paper>
  );
};

export default DashboardNavbar;
