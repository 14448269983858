import { useEffect, type FC } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Card, Container, useMantineTheme } from '@mantine/core';
import StytchLoginComponent from 'src/components/authentication/login/StytchLogin';
import Logo from '../../components/Logo';

const StytchLoginPage: FC = () => {
  const theme = useMantineTheme();

  return (
    <Box
      style={{
        backgroundColor: theme.colors.gray[8],
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Container
        size="sm"
        style={{
          paddingTop: '80px',
          paddingBottom: '80px',
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            mb: 6,
          }}
        >
          <RouterLink to="/">
            <Logo
              style={{
                height: 150,
                width: 150,
              }}
            />
          </RouterLink>
        </Box>
        <Card
          style={{
            display: 'flex',
            flexDirection: 'column',
            p: 4,
          }}
        >
          <Box
            style={{
              alignItems: 'center',
              display: 'contents',
              justifyContent: 'center',
              mb: 3,
            }}
          >
            <StytchLoginComponent />
          </Box>
        </Card>
      </Container>
    </Box>
  );
};

export default StytchLoginPage;
